//DEV
// export const APIURL = "https://a5df-104-45-134-69.ngrok.io"; //do not append slash

//PROD
// export const APIURL = "https://api-test01.us01du.riysacloud.com/api";

// export const APIURL = "https://localhost:44352";

//PROD 2
// export const APIURL = "https://tdecuinsurance.us01du.riysacloud.com/api";
// // export const APIURL = "https://dutest.futuredge.com";
export const APIURL = "https://avm-uap-idp-app-dev-01.eastus.cloudapp.azure.com/api";


//Baker Ripley 
// export const APIURL = "https://rpa.bakerripley.org/api";

//AZURE CLIENT ID
export const AzureClientId = "f3bd1706-f549-41f9-8587-96f5d7413d74";

// export const ManualFieldName = "ERP";
export const ManualFieldName = "AMS";
// export const buildFor = "TDECU";
export const buildFor = "";
export const disableExport = true;
