import styled from "@emotion/styled";
import {
  Alert,
  Button,
  ButtonBase,
  CircularProgress,
  InputBase,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { display } from "@mui/system";
import React from "react";
import { Colors } from "../theme";
import { useFormControl } from "@mui/material/FormControl";
import PersonIcon from "@mui/icons-material/Person";
import LockResetIcon from "@mui/icons-material/LockReset";
import LoginApi, { LoginMS } from "../../api/Login";
import useAuth from "../../utils/useAuth";
import { useMsal } from "@azure/msal-react";
import EngineeringIcon from "@mui/icons-material/Engineering";
import { buildFor } from "../../config";

const Root = styled("div")(({ theme }) => ({
  height: "100vh",
  display: "grid",
  gridTemplateColumns: "3fr 1fr",
}));

const loginRequest = {
  scopes: ["User.Read"],
};

function LoginPage() {
  const [isLoading, setIsLoading] = React.useState(false);
  const { instance } = useMsal();

  const [loginuser, setLoginuser] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [logintype, setLogintype] = React.useState("default");
  const [error, setError] = React.useState("");

  let user = useAuth();
  if (user.token) {
    window.location.href =
      "/dashboard";
  }

  function loginWithMS(e) {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    instance
      .handleRedirectPromise(loginRequest)
      .then((response) => {
        console.log("MS login response: ", response);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        console.log("MS login response: ", response);
        LoginMS(response)
          .then((response) => {
            console.log(response);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem(
              "username",
              response.data.user.ApplicationUser.UserName
            );
            localStorage.setItem(
              "user_email",
              response.data.user.ApplicationUser.Email
            );
            localStorage.setItem(
              "FirstName",
              response.data.user.ApplicationUser.FirstName
            );
            localStorage.setItem(
              "LastName",
              response.data.user.ApplicationUser.LastName
            );
            window.location.href = "/dashboard";
          })
          .catch((error) => {
            console.log("Error", error);
            setError(error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleLogin(e) {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    LoginApi({
      loginuser: loginuser,
      pass: pass,
      logintype: logintype,
    })
      .then((response) => {
        console.log(JSON.stringify(response.data));
        localStorage.setItem("token", response.data.token);
        localStorage.setItem(
          "username",
          response.data.user.ApplicationUser.UserName
        );
        localStorage.setItem(
          "role",
          response.data.user.ApplicationUser.Roles
        );
        localStorage.setItem(
          "user_email",
          response.data.user.ApplicationUser.Email
        );
        localStorage.setItem(
          "FirstName",
          response.data.user.ApplicationUser.FirstName
        );
        localStorage.setItem(
          "LastName",
          response.data.user.ApplicationUser.LastName
        );
        localStorage.setItem(
          "Permissions",
          JSON.stringify(response.data.user.Permissions)
        );
        // window.location.href = "/dashboard";
      })
      .catch((error) => {
        console.log("Error", error);
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Root>
      <div style={{ display: "grid", placeItems: "center" }}>
        <div style={{ width: "280px" }}>
          <div style={{ marginBottom: "24px", textAlign: "center" }}>
            <h4>Welcome to</h4>
            <h3>Document Validator</h3>
          </div>
          <form onSubmit={(e) => handleLogin(e)}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid " + Colors.primary,
              }}
            >
              <PersonIcon color="primary" />
              <InputBase
                value={loginuser}
                onChange={(e) => setLoginuser(e.target.value)}
                required={true}
                fullWidth={true}
                type="text"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Username"
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid " + Colors.primary,
                marginTop: "24px",
              }}
            >
              <LockResetIcon color="primary" />
              <InputBase
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                required={true}
                fullWidth={true}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Password"
                type="password"
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid " + Colors.primary,
                marginTop: "24px",
              }}
            >
              <EngineeringIcon color="primary" />
              <InputBase
                disabled={buildFor == "TDECU" ? true : false}
                value={logintype}
                onChange={(e) => setLogintype(e.target.value)}
                required={true}
                fullWidth={true}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Tenant"
                type="text"
              />
            </div>

            {error !== "" && (
              <Alert severity="error" style={{ marginTop: "16px" }}>
                {error}
              </Alert>
            )}

            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                disableElevation
                fullWidth
                sx={{ marginTop: "16px" }}
                type="submit"
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={23} />
                ) : (
                  "LOGIN"
                )}
              </Button>
            </div>
          </form>
          <div
            style={{
              marginTop: "8px",
              textAlign: "center",
            }}
          >
            <Link
              href="/forgot-password"
              style={{
                textDecoration: "none",
              }}
            >
              Forgot Password?
            </Link>
          </div>

          {/* <Button
                        variant="text"
                        disableElevation
                        fullWidth
                        sx={{ marginTop: "24px", backgroundImage: "/assets/vectors/msLogo.svg" }}
                        onClick={(e) => loginWithMS(e)}
                    >
                        {isLoading ? (
                            <CircularProgress color="inherit" size={23} />
                        ) : null}
                        <img src="/assets/vectors/msLogo.svg" alt="ms login" style={{ opacity: isLoading ? "0.5" : "1" }} />

                    </Button> */}
        </div>
      </div>
      <div
        style={{
          height: "100%",
          background: Colors.primary,
          position: "relative",
        }}
      >
        <div
          style={{
            height: "100%",
            position: "relative",
            overflow: "hidden",
            position: "absolute",
            width: "100%",
          }}
        >
          <div
            style={{
              height: "240px",
              width: "240px",
              background: "white",
              borderRadius: "50%",
              opacity: "0.2",
              position: "absolute",
              top: "-120px",
              left: "-120px",
            }}
          ></div>
          <div
            style={{
              height: "240px",
              width: "240px",
              background: "white",
              borderRadius: "50%",
              opacity: "0.2",
              position: "absolute",
              top: "120px",
              right: "-120px",
            }}
          ></div>
          <div
            style={{
              height: "140px",
              width: "140px",
              background: "white",
              borderRadius: "50%",
              opacity: "0.2",
              position: "absolute",
              top: "300px",
              left: "-60px",
            }}
          ></div>
          <img
            src={"/assets/vectors/wave1.svg"}
            style={{
              width: "100%",
              opacity: "0.2",
              position: "absolute",
              bottom: "10%",
            }}
          />
        </div>
        <div style={{ display: "grid", placeItems: "center", height: "100%" }}>
          <div style={{ display: "grid", placeItems: "center" }}>
            <div
              style={{
                background: "#ffffff",
                width: "fit-content",
                padding: "32px 25px 32px 39px",
                borderRadius: "50%",
              }}
            >
              <img
                src="/assets/vectors/logo_blue.svg"
                style={{ height: "120px", width: "120px" }}
              />
            </div>
            <Typography
              variant="h5"
              style={{ color: "white", marginTop: "24px" }}
            >
              Document Validator
            </Typography>
          </div>
        </div>
      </div>
    </Root>
  );
}

export default LoginPage;
