import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { Colors } from '../../pages/theme';
import { Avatar, List, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import useAuth from '../../utils/useAuth';
import { deepPurple } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

function Navigation() {
    const user = useAuth()
    let navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <AppBar position="static" color='black' elevation={2}>
            <Toolbar sx={{ minHeight: '52px !important' }} >
                <img src='/assets/vectors/logo_white.svg' style={{ width: '20px', marginRight: '16px' }} />
                <Typography variant="h6" component="span" sx={{ flexGrow: 1 }} style={{ color: Colors.white }}>

                    Document Validator Utility
                </Typography>
                <IconButton color="primary" aria-label="upload picture" component="span" sx={{ width: "36px", height: '36px', marginRight: '16px' }}>
                    <SettingsIcon color="white" />
                </IconButton>
                <Avatar aria-describedby={id} onClick={handleClick} style={{ cursor: 'pointer' }} sx={{ bgcolor: deepPurple[400], width: "36px", height: '36px' }}>{user.username.substring(0, 1).toLocaleUpperCase()}</Avatar>
                <Popover
                    elevation={1}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <List component="nav" dense>
                        <ListItemButton
                            onClick={() => navigate("/edit_profile", { replace: true })}
                        >
                            <ListItemIcon style={{ minWidth: '32px' }}>
                                <AccountCircleOutlinedIcon fontSize='small' />
                            </ListItemIcon >
                            <ListItemText primary="Profile" />
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => user.logout()}
                        >
                            <ListItemIcon style={{ minWidth: '32px' }}>
                                <LogoutOutlinedIcon fontSize='small' />
                            </ListItemIcon>
                            <ListItemText primary="Logout" />
                        </ListItemButton>
                    </List>
                </Popover>

            </Toolbar>
        </AppBar>
    )
}

export default Navigation